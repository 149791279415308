<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container">
            <div class="mb-3">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item></el-breadcrumb-item>
              </el-breadcrumb>
            </div>
  
            <h1
              class="mb-4"
              style="font-size: 1.1em; font-weight: 600;"
            >Safari Blogs</h1>
  
            <div class="search_add_section">
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by  name"
                  class="search_by_input"
                  spellcheck="off"
                  style="width: 100%;"
                />
              </div>
              <div>
                <el-button
                  type="primary"
                  @click="$router.push({path: '/add-safari-blog'})"
                >Add A Blog </el-button>
              </div>
            </div>
            <hr />
  

            <div
              v-loading="loading || deleting"
              class="table-responsive"
            >
              <data-tables
                :data="blogs"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getBlogs"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Safari Blogs Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Safari Blogs</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="50px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Safari Blog"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
  
                    <img
                      :src="scope.row.featured_image"
                      style="width: 50px; height: 50px; object-fit: cover;"
                    >
                    <span> <strong> Title:</strong> {{ scope.row.title }} </span><br />
                  </template>
                </el-table-column>
  
              
  
                <el-table-column
                  label="Status"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="100px"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.status ? 'Active' : 'Deactivated'"
                      placement="top"
                    >
                      <el-switch
                        v-model="scope.row.is_active"
                        @change="switchStatus(scope.row.id)"
                        active-color="#13ce66"
                        inactive-color=""
                      >
                      </el-switch>
                    </el-tooltip>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Author"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="100px"
                >
                       <template slot-scope="scope">
 
                
                     {{ scope.row.author }}
                     
                                      </template>
                </el-table-column>


                <el-table-column
                label="Possible Packages"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="auto"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="$router.push({ path: `/blogs/${scope.row.id}` })"
                    type="primary"
                    round
                  >{{ scope.row.package_count }} Packages</el-button>
                </template>
              </el-table-column>
  
           
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                
  
                      <el-button
                        @click="$router.push({path: `edit-safari-blog/${scope.row.id}`})"
                        type="warning"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                        v-if="$store.state.currentRole == 'ADMIN'"
                          @click="deleteBlog(scope.row.id)"
                          type="danger"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
 
  
     
    </div>
  </template>
  
  <script>
  import jwt_decode from "jwt-decode";
  import ScaleOut from "@/components/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  
  export default {
    components: {
     
      ScaleOut,
    },
  
    data() {
      return {
        isLoading: false,
        loading: false,
        loadingError: false,
        deleting: false,
        isEditPackageDialogVisible: false,
       
  
        safari_tours: [],
        blogs:[],
        safariPackage: {},
        packageId: "",
        country_overview: "",
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "room_name",
            value: "",
          },
        ],
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.setCurrentUser();
      if (this.$store.state.currentRole == "ADMIN" ||  this.$store.state.currentRole == "MARKETER" || this.$store.state.currentRole == "AGENT"  ) {
        
        this.getBlogs();
      } 
    },
  
    methods: {
      setCurrentUser() {
        let userToken = JSON.parse(localStorage.getItem("user"));
        let decoded = jwt_decode(userToken);
        this.$store.commit("setCurrentUsername", decoded.data.username);
        this.$store.commit("setCurrentFullName", decoded.data.fullname);
        this.$store.commit("setCurrentRole", decoded.data.role);
        this.$store.commit("setCurrentPhoto", decoded.data.image);
        this.$store.commit("setCurrentCountry", decoded.data.country);
      },
   
  
    
  
     
      async getBlogs() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`api/safari/blogs/get_all/admin`);
          if (request.data.success) {
            
            this.blogs = request.data.blogs;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch blogs now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
  
  
      async switchStatus(blog_id) {
        try {
          this.loading = true;
          let request = await this.$http.get(
            `api/safari/blog/set_blog_status?blog_id=${blog_id}`
          );
          if (request.data.success) {
            this.showSuccessMessage("Success", "Blog status changed");
            this.loading = false;
            this.getBlogs();
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.loading = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change Package status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.loading = false;
        }
      },
  

      async deleteBlog(id) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this blog. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(
                `/safari_tours/delete_safari_blog/${id}`
              );
              if (
                request.data.success &&
                request.data.message === "BLOG_DELETED"
              ) {
                this.getBlogs();
                this.$notify({
                  title: "Success",
                  message: "Blog deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete blog, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  }
  </script>
  
  <style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  input:focus {
    border: 0.01em solid #409eff !important;
  }
  
  .search_add_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  </style>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>